import React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Page from "../common/elements/Page.js"

import ContainerWithBg from "../common/helpers/ContainerWithBg.js"
import Container from "../common/helpers/Container.js"
import CaseFilterableItems from "../common/components/CaseFilterableItems.js"
import CaseElement from "../common/elements/CaseElement.js"
 
function CasesPage() {
  
  const data = useStaticQuery(graphql`
  {
    allWpCategoriaCase {
      nodes {
        id
        name
        slug
      }
    }
    allWpCase {
      nodes {
        id
        slug
        title
        link
        categoriasCases {
          nodes {
            name
            slug
          }
        }
        casesFields {
          comunicacaoCasesDescricao
          comunicacaoCasesLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 19042}) {
      nodeType
      title
      uri
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
  `)

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/cases-hero.png"></link>
      </Helmet>
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-cases-hero"
        className="container-xg tac ai-c jc-c dfc c-white feature-image"
      >
        <h1 className="z1 h1-egg mb-4">Cases</h1>
        <p className="z1 mb-0">Comunicação que gera resultados efetivos</p>
      </ContainerWithBg>
      <ContainerWithBg className="container-820" classBgName="bg-color-golden p-r bg-image-symbol-white">
        <p className="fs-body-extra-large c-purple my-8 mx-3 tac">
          Conheça alguns dos cases dos projetos da <strong>Comunicação Integrada</strong>, Workshops e Cursos nas áreas de <strong>Diagnóstico e Planejamento de Comunicação</strong>, <strong>Mapeamento de Fluxos e Processos</strong> e <strong>Gestão de Riscos Reputacionais</strong>.
        </p>
      </ContainerWithBg>
      <CaseFilterableItems
        Item={CaseElement}
        ItemContainer={Container}
        filters={data.allWpCategoriaCase.nodes}
        filtersKey="slug"
        items={data.allWpCase.nodes}
      />
      {/* <FilterableItems 
        filters={data.allWpCategoriaMaterialEducativo.nodes}
        items={data.allWpMaterialEducativo.nodes}
        Item={BlogPost}
        ItemContainer={Container}
        filtersKey="slug"
        searchKey="categoriasMateriaisEducativos.nodes[0].slug"
      /> */}
    </Page>
  )
}

export default CasesPage;