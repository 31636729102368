import React, { Component } from "react"
import Container from "../helpers/Container.js"
import { utils } from "../utils.js";

class CaseFilterableItems extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filteredItems: [],
      active: 'all'
    }

    this.filterItems = this.filterItems.bind(this);
  }

  componentDidMount() {
    this.setState({ filteredItems: this.props.items });
  }

  filterItems(filter) {
    const { items } = this.props;
    let newItems = items;

    if (filter !== 'all') {
      newItems = items.filter((el) => {
        return utils.getFields(el.categoriasCases.nodes, 'slug').includes(filter)
      });
    }

    this.setState({ filteredItems: newItems, active: filter });
  }

  render() {

    const { filters, Item, ItemContainer, filtersKey } = this.props;
    const { filteredItems, active } = this.state;

    return (
      <Container className="container-xl dfc">
        <p className="c-grey-2 tac mt-4r mb-15r">Navegue por serviços:</p>
        <div className="df fw-wrap jc-c mb-4">
          <div role="button"
            tabIndex="0"
            className={`tag bg-color-golden c-purple round m-2 c-p ${active === 'all' ? 'active-tag' : ''}`}
            onClick={() => this.filterItems('all')}
            onKeyDown={() => this.filterItems('all')}
          >
            Todos
          </div>
          {
            filters.map((el, key) => (
              <div role="button"
                tabIndex="0"
                className={`tag bg-color-golden c-purple round m-2 c-p ${active === el[filtersKey] ? 'active-tag' : ''}`}
                onClick={() => this.filterItems(el[filtersKey])}
                onKeyDown={() => this.filterItems(el[filtersKey])}
                key={key}
              >
                {el.name}
              </div>
            ))
          }
        </div>
          <ItemContainer>
            <div className="row">
            {
              filteredItems.map((el, key) => (
                <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 d-flex justify-content-center align-items-stretch mb-4" key={key}>
                <Item
                  content={el.casesFields.comunicacaoCasesDescricao}
                  toPath={el.link}
                  imageSrc={(el.featuredImage) ? el.featuredImage.node : null}
                  partnerLogo={el.casesFields.comunicacaoCasesLogo}
                />
                </div>
              ))
            }
            </div>
          </ItemContainer>
      </Container>
    )
  }
}


export default CaseFilterableItems;